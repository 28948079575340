.skeleton-job {
    padding: 20px;
    background: white;
    border-radius: 8px;
    margin-bottom: 20px;
}

.skeleton-company, 
.skeleton-title, 
.skeleton-location, 
.skeleton-button {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
}

.skeleton-company {
    height: 30px;
    width: 200px;
    margin-bottom: 10px;
}

.skeleton-title {
    height: 24px;
    width: 300px;
    margin-bottom: 8px;
}

.skeleton-location {
    height: 20px;
    width: 150px;
    margin-bottom: 15px;
}

.skeleton-button {
    height: 40px;
    width: 120px;
}

.skeleton-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    margin: 0 auto;
}

.skeleton-circle-small {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.skeleton-line {
    height: 20px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    margin-bottom: 10px;
    border-radius: 4px;
}

@keyframes loading {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
} 

/* Variaciones de ancho para las líneas */
.skeleton-line.w-25 {
    width: 25%;
}

.skeleton-line.w-50 {
    width: 50%;
}

.skeleton-line.w-75 {
    width: 75%;
}

/* Variaciones de altura */
.skeleton-line.h-sm {
    height: 15px;
}

.skeleton-line.h-lg {
    height: 30px;
}

/* Skeleton para tarjetas */
.skeleton-card {
    padding: 20px;
    background: white;
    border-radius: 8px;
    margin-bottom: 20px;
}

/* Skeleton para imágenes rectangulares */
.skeleton-image {
    width: 100%;
    height: 200px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 8px;
}

/* Skeleton para avatares */
.skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

/* Skeleton para botones */
.skeleton-button {
    height: 40px;
    width: 150px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
} 