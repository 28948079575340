/* Explore Jobs By Location Styles */
.section-head .separator {
    height: 2px;
    width: 50px;
    margin: 15px auto;
}

.section-head .separator.bg-primary {
    background-color: #2e55fa;  /* Using the existing primary color from the template */
}

.city-bx {
    transition: all 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0,24,128,0.1);
}

.city-bx:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,24,128,0.2);
}

.jobs-category-bx .job-bx {
    padding: 30px;
    border-radius: 8px;
}

.category-list li a {
    transition: all 0.3s ease;
    padding: 8px 15px;
    display: block;
    border-radius: 4px;
}

.category-list li a:hover {
    background-color: #e1e7ff;  /* Using existing color scheme */
    color: #2e55fa;
    padding-left: 20px;
} 