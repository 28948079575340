.category-list {
    padding: 0;
    list-style: none;
}

.category-list li {
    margin-bottom: 10px;
}

.category-list li a {
    transition: all 0.3s ease;
    padding: 12px 20px;
    display: block;
    border-radius: 8px;
    color: #333;
    font-weight: 500;
    background: #f8f9fa;
    border: 1px solid #eee;
}

.category-list li a:hover {
    background: #007bff;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.hover-shadow:hover {
    box-shadow: 0 10px 30px rgba(0,0,0,0.1) !important;
    transition: all 0.3s ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.animate__fadeIn {
    animation: fadeIn 1s ease-in;
} 