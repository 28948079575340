/* About Page Styles */

/* Video Container */
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 2rem 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/* Feature Containers */
.feature-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.feature-container:hover {
    transform: translateY(-5px);
}

.icon-lg {
    font-size: 2.5rem;
    color: #2e55fa;
}

/* Benefit Boxes */
.benefit-box {
    background: #f8f9fa;
    border-radius: 8px;
    border-left: 4px solid #2e55fa;
}

.benefit-box h4 {
    color: #2e55fa;
    margin-bottom: 1.5rem;
}

/* Vision Section */
.our-vision {
    padding: 2rem 0;
}

.vision-img {
    color: #2e55fa;
    padding: 2rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .feature-container {
        margin-bottom: 1.5rem;
    }
    
    .benefit-box {
        margin-bottom: 1.5rem;
    }
} 