/* Section Styles */
.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}

.separator-line {
    width: 50px;
    height: 2px;
    background: var(--primary);
    display: inline-block;
}

.text-primary {
    color: var(--primary);
}

.dez-bnr-inr {
    padding: 60px 0;
    background: linear-gradient(45deg, #1e3c72, #2a5298);
    position: relative;
}

.dez-bnr-inr::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
}

.banner-link {
    text-decoration: none;
    display: block;
    transition: all 0.3s ease;
}

.banner-link:hover {
    transform: translateY(-2px);
}

.banner-subtitle {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dez-bnr-inr-entry {
    position: relative;
    z-index: 1;
}

.display-4 {
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 2.5rem;
}

.lead.text-light {
    font-size: 1.4rem;
    opacity: 0.9;
    padding: 0 15px;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .dez-bnr-inr {
        padding: 40px 0;
    }

    .display-4 {
        font-size: 2rem;
        margin-bottom: 1rem !important;
    }

    .lead.text-light {
        font-size: 1rem;
        line-height: 1.4;
    }

    .dez-bnr-inr-entry {
        padding: 0 15px;
    }
}

@media (max-width: 480px) {
    .dez-bnr-inr {
        padding: 30px 0;
    }

    .display-4 {
        font-size: 1.75rem;
    }
} 